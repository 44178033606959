const get = async <T>(url: string, options?: object): Promise<T> => {
  const defaultOptions: object = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const allOptions = { ...defaultOptions, ...options };

  return await fetch(url, allOptions)
    .then(response => response.json() as T)
    .catch(error => {
      throw new Error(`${error.statusText}: ${error.message}`);
    });
};

const post = async <T>(url: string, body: object, options?: object): Promise<T> => {
  const defaultOptions: object = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  const allOptions = { ...defaultOptions, ...options };

  return await fetch(url, allOptions)
    .then(response => response.json() as T)
    .catch(error => {
      throw new Error(`${error.statusText}: ${error.message}`);
    });
};

export { get, post };
