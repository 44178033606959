export const UI_DEVELOPMENT_URL: string = 'http://localhost:4321';
export const UI_PRODUCTION_URL: string = 'https://bookingplatform.xyz';
export const UI_URL: string = process.env.NODE_ENV === 'production' ? UI_PRODUCTION_URL : UI_DEVELOPMENT_URL;

export const API_DEVELOPMENT_URL: string = 'http://localhost:8000';
export const API_PRODUCTION_URL: string = 'https://api.bookingplatform.xyz';
export const API_URL: string = process.env.NODE_ENV === 'production' ? API_PRODUCTION_URL : API_DEVELOPMENT_URL;

export const SITE_DEVELOPMENT_URL: string = 'localhost';
export const SITE_PRODUCTION_URL: string = 'bookingplatform.xyz';
export const SITE_URL: string = process.env.NODE_ENV === 'production' ? SITE_PRODUCTION_URL : SITE_DEVELOPMENT_URL;
