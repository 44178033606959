import { API_URL as apiUrl } from './constants';
import { get, post } from './fetch';

const apiGet = async <T>(path: string): Promise<T> => {
  const url = `${apiUrl}${path}`;
  const response = await get<T>(url);
  return response;
};

const apiPost = async <T>(path: string, body: object): Promise<T> => {
  const url = `${apiUrl}${path}`;
  const response = await post<T>(url, body);
  return response;
};

export { apiGet, apiPost };
